import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AOS from 'aos';
import { FiArrowRight, FiCheck } from 'react-icons/fi';

const Pricing: React.FC = () => {
  AOS.init();

  return (
    <>
      <Header />
      <section className="pt-8 pt-md-11 pb-10 pb-md-15 bg-primary">
        <div className="shape shape-blur-3 svg-shim text-white">
          <svg
            viewBox="0 0 1738 487"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0"
                y1="0"
                x2="1049.98"
                y2="912.68"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="currentColor" stopOpacity=".075" />
                <stop offset="1" stopColor="currentColor" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1 className="display-2 text-white">
                Preços simples e justos para todos.
              </h1>

              <p className="lead text-white-80 mb-6 mb-md-8">
                Todos precisam ter controle financeiro, por isso deixamos que
                você escolha o quanto quer ter
              </p>

              <form className="d-flex align-items-center justify-content-center mb-7 mb-md-9">
                <span className="text-white-80">Anual</span>

                <div className="custom-control custom-switch custom-switch-dark mx-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="billingSwitch"
                    data-toggle="price"
                    data-target=".price"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="billingSwitch"
                  />
                </div>

                <span className="text-white-80">Mensal</span>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="mt-n8 mt-md-n14">
        <div className="container">
          <div className="form-row">
            <div className="col-12 col-md-4" data-aos="fade-up">
              <div className="card shadow-lg mb-6 mb-md-0">
                <div className="card-body">
                  <div className="text-center mb-3">
                    <span className="badge badge-pill badge-primary-soft">
                      <span className="h6 text-uppercase">Basico</span>
                    </span>
                  </div>

                  <div className="d-flex justify-content-center">
                    <span className="h2 mb-0 mt-2">R$</span>
                    <span
                      className="price display-2 mb-0"
                      data-annual="0"
                      data-monthly="0"
                    >
                      0
                    </span>
                    <span className="h2 align-self-end mb-1">/mês</span>
                  </div>

                  <p className="text-center text-muted mb-5" />

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Rich landing pages</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p className="mb-5">100+ components</p>
                  </div>

                  <a href="#!" className="btn btn-block btn-primary-soft">
                    Começe grátis
                    <FiArrowRight className="fe fe-arrow-right ml-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4" data-aos="fade-up">
              <div className="card shadow-lg mb-6 mb-md-0">
                <div className="card-body">
                  <div className="text-center mb-3">
                    <span className="badge badge-pill badge-primary-soft">
                      <span className="h6 text-uppercase">Padrão</span>
                    </span>
                  </div>

                  <div className="d-flex justify-content-center">
                    <span className="h2 mb-0 mt-2">R$</span>
                    <span
                      className="price display-2 mb-0"
                      data-annual="29"
                      data-monthly="49"
                    >
                      29
                    </span>
                    <span className="h2 align-self-end mb-1">/mês</span>
                  </div>

                  <p className="text-center text-muted mb-5" />

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Rich landing pages</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>100+ components</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Flexible licensing</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Speedy build tooling</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p className="mb-5">6 months free support</p>
                  </div>

                  <a href="#!" className="btn btn-block btn-primary">
                    Começar com padrão
                    <FiArrowRight className="fe fe-arrow-right ml-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4" data-aos="fade-up">
              <div className="card shadow-lg mb-md-0">
                <div className="card-body">
                  <div className="text-center mb-3">
                    <span className="badge badge-pill badge-primary-soft">
                      <span className="h6 text-uppercase">Pro</span>
                    </span>
                  </div>

                  <div className="d-flex justify-content-center">
                    <span className="h2 mb-0 mt-2">R$</span>
                    <span
                      className="price display-2 mb-0"
                      data-annual="49"
                      data-monthly="69"
                    >
                      49
                    </span>
                    <span className="h2 align-self-end mb-1">/mês</span>
                  </div>

                  <p className="text-center text-muted mb-5" />

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Rich landing pages</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>100+ components</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Flexible licensing</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Speedy build tooling</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>6 months free support</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>256-bit encryption</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>Guaranteed 100% uptime</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p className="mb-5">Unlimited users</p>
                  </div>

                  <a href="#!" className="btn btn-block btn-primary-soft">
                    Começar com pro
                    <FiArrowRight className="fe fe-arrow-right ml-3" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Pricing;
