import React from 'react';
import Header from '../../components/Header';
import AOS from 'aos';
import { FiCheck } from 'react-icons/fi';

import AppScreenShot from '../../assets/img/screenshots/mobile/home.jpg';
import IphoneDevice from '../../assets/img/devices/iphonex.svg';
import AppScreenShot2 from '../../assets/img/screenshots/mobile/rental.jpg';
import IphoneDevice2 from '../../assets/img/devices/iphonex.svg';

import Illustration1 from '../../assets/img/illustrations/mobile-app.svg';
import Illustration2 from '../../assets/img/illustrations/mobile-login.svg';
import Illustration3 from '../../assets/img/illustrations/online.svg';
import Illustration4 from '../../assets/img/illustrations/mobile-dev.svg';

import AppStoreButton from '../../assets/img/buttons/button-app.png';
import PlayStoreButton from '../../assets/img/buttons/button-play.png';
import Footer from '../../components/Footer';

const App: React.FC = () => {
  AOS.init();

  return (
    <>
      <Header />
      <section className="pt-6 pt-md-8">
        <div className="container">
          <div className="row align-items-center justify-content-center justify-content-md-between">
            <div className="col-10 col-sm-8 col-md-6 order-md-2">
              <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                <div className="device device-iphonex" data-aos="fade-left">
                  <img
                    src={AppScreenShot}
                    className="device-screen"
                    alt="..."
                  />
                  <img src={IphoneDevice} className="img-fluid" alt="..." />
                </div>

                <div
                  className="device device-iphonex"
                  data-aos="fade-left"
                  data-aos-delay="150"
                >
                  <img
                    src={AppScreenShot2}
                    className="device-screen"
                    alt="..."
                  />
                  <img src={IphoneDevice2} className="img-fluid" alt="..." />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5" data-aos="fade-right">
              <h1 className="font-weight-bold">
                Baixe o <span className="text-primary">APP.</span> <br />
                Acesse de onde quiser
              </h1>

              <p className="font-size-lg text-muted mb-6">
                Com o aplicativo da Finance Cores você pode acessar e controlar
                suas finanças a onde você estiver
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 py-md-11 bg-gray-200">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7 text-center">
              <h2 className="font-weight-bold">Feito para você</h2>

              <p className="font-size-lg text-muted mb-7 mb-md-9">
                Nosso App tem como objetivo facilitar o seu controle financeiro,
                para que você consiga cessar de qualquer lugar
              </p>
            </div>
          </div>
          <div className="form-row">
            <div className="col-12 col-lg-6 d-lg-flex pb-1 mb-4">
              <div
                className="card shadow-light-lg overflow-hidden"
                data-aos="fade-up"
              >
                <div className="row">
                  <div className="col-md-4 position-relative">
                    <img
                      src={Illustration1}
                      className="h-75 position-absolute right-0 mt-7 mr-n4"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body py-7 py-md-9 text-center">
                      <h4 className="font-weight-bold">
                        Facilidade, na sua mão
                      </h4>

                      <p className="text-muted mb-0">
                        O App da Finance Cores é limpo e intuitivo, para que
                        você tenha a melhor experiência possível, mas claro, se
                        tiver qualquer dúvida fale conosco
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-lg-flex pb-1 mb-4">
              <div
                className="card shadow-light-lg overflow-hidden text-center"
                data-aos="fade-up"
              >
                <div className="row">
                  <div className="col-md-8">
                    <div className="card-body py-7 py-md-9">
                      <h4 className="font-weight-bold">
                        Suporte, para quando precisar
                      </h4>

                      <p className="text-muted mb-0">
                        Nós oferecemos suporte para o que você precisar, quando
                        precisar
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      src={Illustration3}
                      className="h-75 position-absolute left-0 mt-7"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="card shadow-light-lg overflow-hidden text-center text-lg-left"
                data-aos="fade-up"
              >
                <div className="row">
                  <div className="col-md-4 position-relative">
                    <img
                      src={Illustration2}
                      className="h-75 position-absolute right-0 mt-6"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body py-7 py-md-9">
                      <h4 className="font-weight-bold">Gerencie sua conta</h4>

                      <p className="text-muted mb-0">
                        Nosso app é interligado com a nossa plataforma web,
                        então basta entrar com seu mesmo login. Com o
                        aplicativo, esperamos que você tenha uma experiência
                        muito mais limpa, e eficiente, então é muito importante
                        para o nosso crescimento que você nos de feddbacks
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 py-md-12">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <span className="badge badge-pill badge-primary-soft mb-3">
                <span className="h6 text-uppercase">Preço</span>
              </span>

              <h2>
                Não se preocupe com os preços. <br />
                <span className="text-primary">Nosso app é gratuito</span>.
              </h2>

              <p className="font-size-lg text-gray-700 mb-6">
                Nosso App é gratuito, indiferente do seu plano, basta baixar e
                entrar com sua conta
              </p>

              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p>100% mais eficiente</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p className="mb-lg-0">Sem perda de tempo</p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="d-flex">
                    <span className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </span>

                    <p>Acesse de qualquer</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle badge-primary-soft mr-1 mr-4">
                      <FiCheck className="fe fe-check" />
                    </div>

                    <p className="mb-0">Quando quiser</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                src={Illustration4}
                alt="..."
                className="img-fluid mw-md-130"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="pt-6 pt-md-8">
        <div className="container pb-6 pb-md-8 border-bottom">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h3 className="font-weight-bold mb-1">Baixe agora!</h3>

              <p className="text-muted mb-6 mb-md-0">Gerencie com estilo!</p>
            </div>

            <div className="col-auto">
              <a href="#!" className="text-reset d-inline-block mr-1">
                <img
                  src={AppStoreButton}
                  className="img-fluid"
                  alt="..."
                  style={{ maxWidth: 155 }}
                />
              </a>

              <a href="#!" className="text-reset d-inline-block">
                <img
                  src={PlayStoreButton}
                  className="img-fluid"
                  alt="..."
                  style={{ maxWidth: 155 }}
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default App;
