import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import App from '../pages/App';
import Pricing from '../pages/Pricing';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/app" component={App} />
    <Route path="/precos" component={Pricing} />

    <Route component={NotFound} />
  </Switch>
);

export default Routes;
