import React from 'react';
import Instagram from '../../assets/img/icons/social/instagram.svg';
import Facebook from '../../assets/img/icons/social/facebook.svg';
import Twitter from '../../assets/img/icons/social/twitter.svg';
import Linkedin from '../../assets/img/icons/social/linkedin.svg';

const Footer: React.FC = () => {
  return (
    <>
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <footer className="py-8 py-md-11 bg-gray-200">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <img
                src="./assets/img/brand.svg"
                alt="..."
                className="footer-brand img-fluid mb-2"
              />

              <p className="text-gray-700 mb-2">Segue a gente</p>

              <ul className="list-unstyled list-inline list-social mb-6 mb-md-0">
                <li className="list-inline-item list-social-item mr-3">
                  <a href="#!" className="text-decoration-none">
                    <img
                      src={Instagram}
                      className="list-social-icon"
                      alt="..."
                    />
                  </a>
                </li>
                <li className="list-inline-item list-social-item mr-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/financecores/?modal=admin_todo_tour"
                    className="text-decoration-none"
                  >
                    <img
                      src={Facebook}
                      className="list-social-icon"
                      alt="..."
                    />
                  </a>
                </li>
                <li className="list-inline-item list-social-item mr-3">
                  <a href="#!" className="text-decoration-none">
                    <img src={Twitter} className="list-social-icon" alt="..." />
                  </a>
                </li>
                <li className="list-inline-item list-social-item">
                  <a href="#!" className="text-decoration-none">
                    <img
                      src={Linkedin}
                      className="list-social-icon"
                      alt="..."
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-4 col-lg-2">
              <h6 className="font-weight-bold text-uppercase text-gray-700">
                Cliente
              </h6>

              <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Fale conosco
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Suporte
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-4 col-lg-2">
              <h6 className="font-weight-bold text-uppercase text-gray-700">
                Blog
              </h6>

              <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Nosso blog
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Posts recentes
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Categorias
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">
              <h6 className="font-weight-bold text-uppercase text-gray-700">
                Páginas
              </h6>

              <ul className="list-unstyled text-muted mb-0">
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Home
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Nosso App
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Sobre nós
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Preços
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-4 col-lg-2">
              <h6 className="font-weight-bold text-uppercase text-gray-700">
                Política
              </h6>

              <ul className="list-unstyled text-muted mb-0">
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Política de privacidade
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Termos de uso
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
