import React from 'react';
import Logo from '../../assets/img/brand.svg';
import { Link } from 'react-router-dom';
import { FiLogIn, FiChevronRight } from 'react-icons/fi';

const Header: React.FC = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={Logo} className="navbar-brand-img" alt="..." />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fe fe-x" />
          </button>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarLandings"
                data-toggle="dropdown"
                to="/"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarPages"
                data-toggle="dropdown"
                to="/app"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Nosso App
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarAccount"
                data-toggle="dropdown"
                to="/precos"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Preços
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDocumentation"
                data-toggle="dropdown"
                href="https://blog.financecores.com"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Blog
              </a>
            </li>
          </ul>
          <a
            className="nav-link  ml-auto login-link"
            id="navbarAccount"
            data-toggle="dropdown"
            href="https://app.financecores.com"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Login
            <FiLogIn className="fe fe-arrow-right d-none d-md-inline ml-3" />
          </a>

          <a
            className="navbar-btn btn btn-sm btn-primary lift ml-auto"
            href="https://app.financecores.com/cadastrar"
            style={{ backgroundColor: '#f3b340' }}
          >
            Começe já
            <FiChevronRight className="fe fe-arrow-right d-none d-md-inline ml-3" />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
